import React from "react"
import { Box } from "theme-ui"
import { graphql } from "gatsby"
import { Section } from "elements-ui"
import Img from "gatsby-image"
import { SEO } from "@maker-ui/seo"

import ServiceHeader from "../components/ServiceHeader"
import PortableText from "../components/PortableText"
import Raves from "../components/Raves"

export default ({ data }) => {
  const {
    _rawBody,
    name,
    subtitle,
    raves,
    seo,
  } = data.allSanityService.nodes[0]

  return (
    <>
      <SEO title={seo.title} description={seo.description} />
      <ServiceHeader>{name}</ServiceHeader>
      <Section
        sx={{ variant: "pageBody", li: { mb: 30, fontSize: [16, 18, 20] } }}
      >
        <h2>{subtitle}</h2>
        <PortableText blocks={_rawBody} />
      </Section>
      <Raves raves={raves} service="Retreats & Offsites" />
      <Section maxWidth="1500px" padding="60px 30px 80px" bg="#f5f5f5">
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gridGap: 10,
            img: {
              height: ["250px", "350px"],
            },
          }}
        >
          {data.allFile.nodes.map((item) => (
            <Img key={item.base} fluid={item.childImageSharp.fluid} />
          ))}
        </Box>
      </Section>
    </>
  )
}

export const query = graphql`
  query {
    allSanityService(filter: { slug: { eq: "retreats" } }) {
      nodes {
        ...ServiceFragment
      }
    }
    allFile(
      filter: {
        extension: { regex: "/(jpg)/" }
        relativeDirectory: { eq: "retreats" }
      }
    ) {
      nodes {
        ...ImageFragment
      }
    }
  }
`
